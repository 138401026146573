import { Alert, Button, Badge, FileInput } from "flowbite-react";

import { DayPicker } from "react-day-picker";
import { format } from "date-fns";
import "react-day-picker/dist/style.css";
import "./App.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { useEffect, useState } from "react";

dayjs.extend(customParseFormat);
const CACHE_WORD_KEY = "word";
const CACHE_EXCEL_KEY = "excel";

const holidays = {
  2024: [
    ["New Year's Day", "01/01"],
    ["Chinese New Year", "12/02"],
    ["Good Friday", "29/03"],
    ["Hari Raya Puasa", "10/04"],
    ["Labour Day", "01/05"],
    ["Vesak Day", "22/05"],
    ["Hari Raya Haji", "17/06"],
    ["National Day", "09/08"],
    ["Deepavali", "31/10"],
    ["Christmas Day", "25/12"],
  ],
  2025: [
    ["New Year's Day", "01/01"],
    ["Chinese New Year", "29/02"],
    ["Chinese New Year", "30/02"],
    ["Hari Raya Puasa", "31/03"],
    ["Good Friday", "18/04"],
    ["Labour Day", "01/05"],
    ["Vesak Day", "12/05"],
    ["Hari Raya Haji", "07/06"],
    ["National Day", "09/08"],
    ["Deepavali", "20/10"],
    ["Christmas Day", "25/12"],
  ],
};

const thisYear = new Date().getFullYear();

function PublicHoliday() {
  return (
    <div>
      <Badge size={2} color="purple">
        Public Holiday
      </Badge>
      <ul style={{ padding: 9 }}>
        {holidays[thisYear].map((e) => (
          <li key={e[0]}>
            - {e[1]} {e[0]}{" "}
          </li>
        ))}
      </ul>
    </div>
  );
}

// Takes in the month we want to generate the timesheet for
function getTimesheetMapping(month, medical, leave) {
  console.log("Generating timesheet", month);
  // {S1}, {E1}, {B1}
  let replaceStringList = [];

  let totalDaysInMonth = dayjs(month).daysInMonth();
  let monthInstance = dayjs(month).startOf("month");

  for (let i = 1; i <= 31; i++) {
    if (i > totalDaysInMonth) {
      replaceStringList.push(`{S${i}}`, "");
      replaceStringList.push(`{E${i}}`, "");
      replaceStringList.push(`{B${i}}`, "");
      continue;
    }

    // Check of Holiday, AL and MC first
    let current = monthInstance.date(i);
    let dayName = current.format("ddd");
    let dd_mm = current.format("DD/MM");

    //   console.log(dd_mm);
    if (isHoliday(dd_mm)) {
      console.log(dd_mm + " is a holiday");
      replaceStringList.push(`{S${i}}`, "");
      replaceStringList.push(`{E${i}}`, "");
      replaceStringList.push(`{B${i}}`, "PH");
      continue;
    }

    if (medical.includes(dd_mm)) {
      console.log(dd_mm + " is a pto");
      replaceStringList.push(`{S${i}}`, "");
      replaceStringList.push(`{E${i}}`, "");
      replaceStringList.push(`{B${i}}`, "ML");
      continue;
    }

    if (leave.includes(dd_mm)) {
      console.log(dd_mm + " is a pto");
      replaceStringList.push(`{S${i}}`, "");
      replaceStringList.push(`{E${i}}`, "");
      replaceStringList.push(`{B${i}}`, "AL");
      continue;
    }

    if (["Sat", "Sun"].includes(dayName)) {
      replaceStringList.push(`{S${i}}`, "");
      replaceStringList.push(`{E${i}}`, "");
      replaceStringList.push(`{B${i}}`, "");
      continue;
    }

    if (dayName === "Fri") {
      replaceStringList.push(`{S${i}}`, "0830");
      replaceStringList.push(`{E${i}}`, "1730");
      replaceStringList.push(`{B${i}}`, "");
      continue;
    } else {
      replaceStringList.push(`{S${i}}`, "0830");
      replaceStringList.push(`{E${i}}`, "1800");
      replaceStringList.push(`{B${i}}`, "");
      continue;
    }
  }

  console.log(replaceStringList);
  return replaceStringList.join(",");
}

function getExcelTimesheetMapping(month, medical, leave) {
  console.log("Get Excel Timesheet Cell Data", month);
  // [B12, C12|D12|E12|G12]
  let replaceList = [];

  let totalDaysInMonth = dayjs(month).daysInMonth();
  let monthInstance = dayjs(month).startOf("month");
  let startIndex = 12;

  for (let i = 1; i <= 31; i++) {
    if (i > totalDaysInMonth) {
      continue;
    }

    // Check of Holiday, AL and MC first
    let current = monthInstance.date(i);
    let dayName = current.format("ddd");
    let dd_mm = current.format("DD/MM");

    // Prepare our dates first
    let rowIndex = startIndex + i - 1;
    replaceList.push("B" + rowIndex);
    replaceList.push(current.format("D/MM/YYYY"));

    if (isHoliday(dd_mm)) {
      console.log(dd_mm + " is a holiday");
      replaceList.push("D" + rowIndex);
      replaceList.push("1");

      continue;
    }

    if (medical.includes(dd_mm)) {
      console.log(dd_mm + " is a pto");
      replaceList.push("E" + rowIndex);
      replaceList.push("1");
      continue;
    }

    if (leave.includes(dd_mm)) {
      console.log(dd_mm + " is a pto");
      replaceList.push("G" + rowIndex);
      replaceList.push("1");
      continue;
    }

    if (["Sat", "Sun"].includes(dayName)) {
      continue;
    } else {
      replaceList.push("C" + rowIndex);
      replaceList.push("1");
    }
  }

  console.log(replaceList);
  return replaceList.join(",");
}

// date should be in dd/mm
function isHoliday(date) {
  return holidays[thisYear].flat().includes(date);
}

function getMonthWeekendDates(month) {
  let checkMonth = dayjs(month).startOf("month");
  let totalDaysInMonth = checkMonth.daysInMonth();

  let toDisable = [];
  for (let i = 0; i < totalDaysInMonth; i++) {
    let day = checkMonth.add(i, "days");
    if (["Sat", "Sun"].includes(day.format("ddd"))) {
      toDisable.push(day.toDate());
    }
  }

  return toDisable;
}

// byteArray is a Uint8Array
function arrayBufferToHex(byteArray) {
  const hexParts = [];

  byteArray.forEach((byte) => {
    const hex = byte.toString(16).padStart(2, "0"); // Convert each byte to hex and pad with '0' if needed
    hexParts.push(hex);
  });

  return hexParts.join("");
}

function hexToByteArray(hexString) {
  if (hexString.length % 2 !== 0) {
    throw new Error("Hex string must have an even number of characters");
  }

  const buffer = new ArrayBuffer(hexString.length / 2);
  const byteArray = new Uint8Array(buffer);

  for (let i = 0; i < hexString.length; i += 2) {
    byteArray[i / 2] = parseInt(hexString.substr(i, 2), 16); // Convert every 2 hex characters to a byte
  }

  return byteArray;
}

// Cache last loaded template
function cacheTemplate(arrayBuffer, key) {
  localStorage.setItem(key, arrayBufferToHex(arrayBuffer));
}

// Generate from last loaded template
function getCachedTemplate(key) {
  let cachedHex = localStorage.getItem(key);

  if (!cachedHex) {
    alert(
      "there's nothing cached, load a template and it'll be cached in your local storage"
    );
    return null;
  }

  return hexToByteArray(cachedHex);
}

function App() {
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedOption, setSelectionOption] = useState("Medical");
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [disabledDates, setDisabledDates] = useState([]);
  const [isDocxLoaded, setIsDocxLoaded] = useState(false);
  const [isExcelLoaded, setIsExcelLoaded] = useState(false);

  const [specialDates, setSpecialDates] = useState({
    Medical: [],
    Leave: [],
  });

  useEffect(() => {
    setDisabledDates(getMonthWeekendDates(selectedMonth));
  }, [selectedMonth]);

  function displaySpecialDays(key) {
    return (
      <ul style={{ padding: 9 }}>
        {specialDates[key].map((e) => (
          <li key={key + e}>- {e}</li>
        ))}
      </ul>
    );
  }

  function Info() {
    return (
      <div>
        <Badge
          size={2}
          color="dark"
          style={{ fontWeight: "bold" }}
          onClick={() => setSelectionOption("Leave")}
        >
          Information
        </Badge>
        <p style={{ padding: 9 }}>
          Everything is processed locally using WebAssembly and Javascript. View
          the{" "}
          <a
            href="https://www.youtube.com/watch?v=9eriY9HXRCA"
            className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
            target="_blank"
            rel="noreferrer"
          >
            tutorial video
          </a>
          . Or download the{" "}
          <a
            href="https://public.r2.hongchai.cc/template_demo.docx"
            className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
          >
            document template
          </a>{" "}
          and{" "}
          <a
            href="https://public.r2.hongchai.cc/template_demo.xlsx"
            className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
          >
            excel template.
          </a>{" "}
          Also{" "}
          <a
            href={`mailto:?subject=Timesheet for ${format(
              selectedMonth,
              "MMMM yyyy"
            )}&body=Hi MANAGER_NAME,%0AI have attached my timesheet for the month of ${format(
              selectedMonth,
              "MMMM yyyy"
            )}.%0APlease let me know if there are any mistakes in it.%0AThank you.%0A%0ABest regards,%0AYOUR_NAME`}
            className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
          >
            mail template
          </a>{" "}
          for a fast compose.
        </p>
      </div>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ flex: 3 }}>
        {Info()}
        {PublicHoliday()}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Badge
            size={2}
            color="warning"
            style={
              selectedOption === "Medical"
                ? { fontWeight: "bold", fontSize: "1.4em" }
                : {}
            }
            onClick={() => setSelectionOption("Medical")}
          >
            Medical Leave{" "}
            {specialDates["Medical"].length > 0
              ? `(${specialDates["Medical"].length})`
              : ""}
          </Badge>

          {displaySpecialDays("Medical")}
          <Badge
            size={2}
            color="success"
            style={
              selectedOption === "Leave"
                ? { fontWeight: "bold", fontSize: "1.4em" }
                : {}
            }
            onClick={() => setSelectionOption("Leave")}
          >
            Annual Leave{" "}
            {specialDates["Leave"].length > 0
              ? `(${specialDates["Leave"].length})`
              : ""}
          </Badge>

          {displaySpecialDays("Leave")}
        </div>
        <Badge size={2} color="dark">
          Word Template File
        </Badge>

        <div
          id="fileUpload"
          className="max-w-md"
          style={{ padding: 9, marginBottom: "6px" }}
        >
          <FileInput
            id="template"
            style={{ fontSize: "0.8em" }}
            onChange={function (e) {
              const reader = new FileReader();
              reader.onload = function () {
                const arrayBuffer = this.result;
                const array = new Uint8Array(arrayBuffer);

                const loaded = loadTemplate(array); // eslint-disable-line
                setIsDocxLoaded(loaded);

                if (!loaded) {
                  alert("Failed reading doc template file");
                }

                cacheTemplate(array, CACHE_WORD_KEY);
              };

              if (e.target.files.length > 0)
                reader.readAsArrayBuffer(e.target.files[0]);
            }}
          />

          <Button
            id="generate"
            style={{ marginTop: 9, width: "100%" }}
            disabled={!isDocxLoaded}
            onClick={() => {
              let config = getTimesheetMapping(
                selectedMonth,
                specialDates["Medical"],
                specialDates["Leave"]
              );

              /* eslint-disable  */
              generateTemplate(
                `Timesheet ${format(selectedMonth, "MMM")}.docx`,
                format(selectedMonth, "MMM/yyyy"),
                config
              );
              /* eslint-enable */
            }}
          >
            Generate Timesheet
          </Button>
        </div>
        <Badge size={2} color="dark">
          Excel Template File
        </Badge>

        <div
          id="fileUpload"
          className="max-w-md"
          style={{ padding: 9, marginBottom: "6px" }}
        >
          <FileInput
            id="template"
            style={{ fontSize: "0.8em" }}
            onChange={function (e) {
              const reader = new FileReader();
              reader.onload = function () {
                const arrayBuffer = this.result;
                const array = new Uint8Array(arrayBuffer);

                const loaded = loadExcelTemplate(array); // eslint-disable-line
                setIsExcelLoaded(loaded);

                if (!loaded) {
                  alert("Failed reading excel template file");
                }

                cacheTemplate(array, CACHE_EXCEL_KEY);
              };

              if (e.target.files.length > 0)
                reader.readAsArrayBuffer(e.target.files[0]);
            }}
          />

          <Button
            id="generate"
            style={{ marginTop: 9, width: "100%" }}
            disabled={!isExcelLoaded}
            onClick={() => {
              let config = getExcelTimesheetMapping(
                selectedMonth,
                specialDates["Medical"],
                specialDates["Leave"]
              );

              /* eslint-disable  */
              generateExcelTemplate(
                `Timesheet ${format(selectedMonth, "MMM")}.xlsx`,
                format(selectedMonth, "MMM/yyyy"),
                config
              );
              /* eslint-enable */
            }}
          >
            Generate Timesheet
          </Button>
        </div>

        <Badge size={2} color="dark">
          Generate from cache (uses your last Word and Excel file)
        </Badge>

        <Button
          id="generate"
          style={{ marginTop: 9, width: "100%" }}
          onClick={() => {
            let config = getTimesheetMapping(
              selectedMonth,
              specialDates["Medical"],
              specialDates["Leave"]
            );

            const docUIntArray = getCachedTemplate(CACHE_WORD_KEY);

            if (!docUIntArray) return;
            loadTemplate(docUIntArray); // eslint-disable-line

            /* eslint-disable  */
            generateTemplate(
              `Timesheet ${format(selectedMonth, "MMM")}.docx`,
              format(selectedMonth, "MMM/yyyy"),
              config
            );
            /* eslint-enable */
          }}
        >
          Generate Word Timesheet
        </Button>

        <Button
          id="generate"
          style={{ marginTop: 9, width: "100%" }}
          onClick={() => {
            let config = getExcelTimesheetMapping(
              selectedMonth,
              specialDates["Medical"],
              specialDates["Leave"]
            );

            const excelDocUIntArray = getCachedTemplate(CACHE_EXCEL_KEY);

            if (!excelDocUIntArray) return;
            loadExcelTemplate(excelDocUIntArray); // eslint-disable-line

            /* eslint-disable  */
            generateExcelTemplate(
              `Timesheet ${format(selectedMonth, "MMM")}.xlsx`,
              format(selectedMonth, "MMM/yyyy"),
              config
            );
            /* eslint-enable */
          }}
        >
          Generate Excel Timesheet
        </Button>
      </div>
      <div style={{ flex: 8 }}>
        {
          <DayPicker
            mode="multiple"
            fromYear={2024}
            modifiers={{
              publicHolidays: holidays[thisYear].map((e) =>
                dayjs(e[1] + "/" + thisYear, "DD/MM/YYYY").toDate()
              ),
              medical: specialDates["Medical"].map((e) =>
                dayjs(e + "/" + thisYear, "DD/MM/YYYY").toDate()
              ),
              leave: specialDates["Leave"].map((e) =>
                dayjs(e + "/" + thisYear, "DD/MM/YYYY").toDate()
              ),
            }}
            modifiersStyles={{
              publicHolidays: {
                color: "rgb(85 33 181)",
                backgroundColor: "rgb(237, 235, 254)",
                fontWeight: "600",
              },
              medical: {
                color: "rgb(114, 59, 19)",
                backgroundColor: "rgb(253, 246, 178)",
                fontWeight: "600",
              },
              leave: {
                color: "rgb(3, 84, 63)",
                backgroundColor: "rgb(222, 247, 236)",
                fontWeight: "600",
              },
            }}
            selected={selectedDates}
            month={selectedMonth}
            disabled={disabledDates}
            onMonthChange={setSelectedMonth}
            onSelect={(date) => {
              // Check if is new item or remove item
              if (date.length > selectedDates.length) {
                // Check if the clicked item is a Public Holiday
                let selectedDate = format(date[date.length - 1], "dd/MM");

                if (holidays[thisYear].find((e) => e[1] === selectedDate)) {
                  console.log(
                    "clicked date is a public holiday, nothing to do"
                  );
                  return;
                }

                let copy = {
                  ...specialDates,
                };

                copy[selectedOption].push(selectedDate);
                copy[selectedOption].sort();

                setSpecialDates(copy);
              } else {
                // Removed something
                console.log("Removed item");
                let formattedDates = date.map((e) => format(e, "dd/MM"));

                let keys = Object.keys(specialDates);

                let allSpecialDates = [];
                for (let i = 0; i < keys.length; i++) {
                  allSpecialDates = allSpecialDates.concat(
                    specialDates[keys[i]]
                  );
                }

                let difference = "";

                for (let i = 0; i < allSpecialDates.length; i++) {
                  let checkValue = allSpecialDates[i];
                  if (!formattedDates.includes(checkValue)) {
                    difference = checkValue;
                    console.log(checkValue + " should be removed");
                  }
                }

                for (let i = 0; i < keys.length; i++) {
                  const key = keys[i];
                  let indexToRemove = specialDates[key].indexOf(difference);

                  if (indexToRemove !== -1) {
                    let copy = { ...specialDates };
                    copy[key].splice(indexToRemove, 1);
                    copy[key].sort();
                    setSpecialDates(copy);
                    break;
                  }
                }
              }

              setSelectedDates(date);
            }}
            footer={
              <Alert color="info" withBorderAccent>
                Click on <b>Medical Leave</b> or <b>Annual Leave</b> on the left
                and select a day.{" "}
              </Alert>
            }
          />
        }
      </div>
    </div>
  );
}

export default App;
